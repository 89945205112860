import React from "react";
import styled from "styled-components";
import Hidden from "@material-ui/core/Hidden";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { SLOT_FILTER_ITEMS } from "../../../constants";
import { setSlotsFilter } from "src/store/components/gameCategories/gameActions";
import { FormattedMessage } from "react-intl";
import "./style.css";

const Filters = styled.section`
  width: 100%;
  float: left;
  position: relative;
`;
const Filter = styled.div`
  float: right;
  margin-right: 31px;
  padding-bottom: 10px;
`;
const Label = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  color: #b6b0bf;
  margin-right: 5px;
`;
const FilterFocusWrapper = styled.div`
  &:focus {
    outline: none;
  }
`;

const MGFiltersComponent = () => {
  const { slotLinesFilter, selectedCategory, loading: isLoadingGames } = useSelector((state) => state.gameCategories);

  const dispatch = useDispatch();

  let filterByCategory = slotLinesFilter.find((item) => item[selectedCategory]) || [];
  const checkBoxFilter = (filterByCategory[selectedCategory] || []).map((item) => item.toString());
  const lines = slotLinesFilter.filter((item) => Object.keys(item)[0] !== selectedCategory);

  const handleChange = (value) => {
    if (filterByCategory[selectedCategory].find((item) => item.toString() === value.toString())) {
      if (filterByCategory[selectedCategory].length > 1) {
        let newValue = filterByCategory[selectedCategory].filter((item) => item.toString() !== value.toString());
        dispatch(setSlotsFilter([{ [selectedCategory]: newValue }, ...lines]));
      }
    } else {
      let newValue = [...filterByCategory[selectedCategory], value];
      dispatch(setSlotsFilter([{ [selectedCategory]: newValue }, ...lines]));
    }
  };
  return (
    <Hidden smDown>
      <Filters>
        <Filter>
          <Label>
            <FormattedMessage id="MGFilter.SortLabel" defaultMessage="Sort By:" />
          </Label>

          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={filterByCategory[selectedCategory] || []}
            classes={{
              root: "filterRoot",
              icon: "filterIcon",
              select: "filterSelect"
            }}
            MenuProps={{
              style: { position: "fixed" },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  width: "200px",
                  background: "rgba(13, 13, 13, 0.7)",
                  border: "1px solid rgba(255, 255, 255, 0.2)",
                  borderRadius: "2px",
                  marginTop: "2px"
                }
              }
            }}
            renderValue={(selected) =>
              selected.length === SLOT_FILTER_ITEMS.length ? (
                <FormattedMessage id="MGFilter.All" />
              ) : (
                `${selected.length} checked`
              )
            }
            disableUnderline
          >
            {!isLoadingGames &&
              !!slotLinesFilter.length &&
              SLOT_FILTER_ITEMS.map((item) => {
                const checkedValue = checkBoxFilter.indexOf(item.value.toString()) > -1;
                return (
                  <FilterFocusWrapper key={item.value}>
                    <MenuItem
                      key={item.text}
                      value={item.value}
                      classes={{ root: "filterMenuItem", selected: "filterMenuItemSelected" }}
                      onClick={() => handleChange(item.value)}
                    >
                      <Checkbox
                        size="small"
                        checked={checkedValue}
                        classes={{ root: "filterMenuCheckbox", checked: "filterMenuCheckboxChecked" }}
                      />
                      <span className={checkedValue ? "filterMenuCheckboxChecked" : ""}>
                        <FormattedMessage id={item.text} />
                      </span>
                    </MenuItem>
                  </FilterFocusWrapper>
                );
              })}
          </Select>
        </Filter>
      </Filters>
    </Hidden>
  );
};

export default MGFiltersComponent;
