import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Cookies from "js-cookie";
import Grid from "@material-ui/core/Grid";
import { FormattedMessage } from "react-intl";
import { selectCategory } from "src/store/components/gameCategories/gameActions";
import LoaderComponent from "../common/LoaderComponent";
import HeaderComponent from "../Header/HeaderComponent";
import CategoriesComponent from "./CategoriesComponent";
import GamesScrollList from "./GamesScrollList";
import GamesLayout from "../layout/Basic/GamesLayout";
import { sidebarMenu } from "src/hocs/defaultHOCs";
import ExternalGame from "./GameItemComponents/ExternalGame";
import MGFiltersComponent from "./Filters/MGFiltersComponent";
import { useHistory } from "react-router-dom";
import { getBP } from "src/utils/styleUtils";
import { FixedHeader } from "./FixedHeader";

const StyledGamesComponent = styled.div`
  .games {
    padding: 15px 30px 0 30px;
    flex-grow: 1;
    z-index: 10;
    position: relative;
    height: calc(100vh - 220px);
    -webkit-overflow-scrolling: auto;

    @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
      height: ${({ isLoadingGames }) => (isLoadingGames ? "100vh" : "auto")};
      padding: ${({ isLoadingGames }) => (isLoadingGames ? "0px 15px 0 15px" : "115px 15px 0 15px")};
    }
  }

  #scrollList {
    overflow: auto !important;
  }

  .root {
    flex-grow: 1;
  }
`;

const GamesComponent = () => {
  const {
    games: { games },
    categories,
    loading: isLoadingGames,
    slotLinesFilter,
    selectedCategory
  } = useSelector((state) => state.gameCategories);
  const { games: openedGames, hideLobby } = useSelector((state) => state.multiView);
  const { token } = useSelector((state) => state.user);
  const { operatorId } = useSelector((state) => state.startUp);
  const history = useHistory();
  const dispatch = useDispatch();

  const [catName, setCatName] = useState("");

  const redirect = async () => {
    const openGameUrl = localStorage.getItem("openGameUrl");
    const root = document.getElementById("root");

    const loginParams = JSON.parse(Cookies.get("loginParams") || "{}");
    if (openGameUrl) {
      localStorage.removeItem("openGameUrl");
      Cookies.remove("openGameUrl");
      root.style.visibility = "hidden";

      if (loginParams.alert) {
        alert(window.location.href);
      }

      window.location.href = openGameUrl;
    }
  };

  useEffect(() => {
    if (token && !isLoadingGames) {
      let categoryName = localStorage.getItem("selectedCategory");
      if (!!token && categories.length) {
        const categoryExists = categories.find((item) => item.Name.toLowerCase() === categoryName);
        if (!categoryExists) {
          categoryName = categories[0].Name.toLowerCase();
        }
        if (catName !== categoryName) {
          history.replace({
            pathname: `/category/${categoryName}`,
            search: `?operatorId=${operatorId}`,
            state: {
              category: categoryName
            }
          });
          dispatch(selectCategory(categoryName));
          setCatName(categoryName);
          redirect();
        }
      }
    }
  });

  const linesFilter = slotLinesFilter.find((item) => item[selectedCategory]) || [];
  const filteredGames =
    games && games.length
      ? games.filter((externalGame) =>
          (linesFilter[selectedCategory] || []).find((filter) =>
            filter[0] > -1
              ? externalGame.linesCount >= filter[0] &&
                externalGame.linesCount <= filter[1] &&
                !externalGame.jackpotGame
              : externalGame.jackpotGame
          )
        )
      : [];

  return (
    <GamesLayout>
      <FixedHeader>
        <HeaderComponent />
        <CategoriesComponent />
      </FixedHeader>
      <MGFiltersComponent />
      <StyledGamesComponent isLoadingGames={isLoadingGames}>
        <GamesScrollList classNameData={"games " + ((openedGames.length > 0 && !hideLobby && " hide") || "")}>
          {isLoadingGames ? (
            <LoaderComponent message={<FormattedMessage id="GLloadingGames" />} />
          ) : (
            <Grid container className="root">
              {filteredGames.map((filteredGame) => (
                <ExternalGame key={filteredGame.gameId + filteredGame.gameName} item={filteredGame} />
              ))}
            </Grid>
          )}
        </GamesScrollList>
      </StyledGamesComponent>
    </GamesLayout>
  );
};

export default sidebarMenu(GamesComponent);
