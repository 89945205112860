const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  hideLobby: false,
  games: [],
  lobbyMode: -1,
  switchGame: {}
};

const multiViewSlice = createSlice({
  name: "multiView",
  initialState,
  reducers: {
    goToLobby: (state) => {
      state.hideLobby = true;
    },
    goToMultiView: (state) => {
      state.hideLobby = false;
      state.lobbyMode = -1;
      state.switchGame = {};
    },
    addGame: (state, action) => {
      let actionButton = JSON.parse(localStorage.getItem("urlParams"));
      const directLink = action.payload.directLink && actionButton.action === "lobby" ? 0 : 1;
      state.games = [...state.games, action.payload];
      state.hideLobby = action.payload.directLink ? action.payload.directLink : false;
      state.lobbyMode = action.payload.directLink ? directLink : -1;
    },
    gameClose: (state, action) => {
      state.games = action.payload;
      state.lobbyMode = -1;
    },
    switchLobbyGame: (state, action) => {
      state.hideLobby = false;
      state.games = action.payload;
      state.switchGame = {};
    },
    updateLobbyMode: (state, action) => {
      const { lobbyMode, switchGame } = action.payload;
      state.lobbyMode = lobbyMode;
      state.switchGame = switchGame;
    },
    voidAction: (state, action) => {
    }
  }
});

const { reducer, actions } = multiViewSlice;
export const { addGame, gameClose, goToLobby, goToMultiView, switchLobbyGame, updateLobbyMode, voidAction } = actions;
export default reducer;
