import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import CategoryIcon, { ColoredIcon, Icon, SimpleIcon } from "./CategoryIcon";
import { isLobbyInIframe } from "../../utils/helpers";
import { getBP } from "src/utils/styleUtils";

const CategoriesComponentWrap = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 18px;

  @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
    padding-bottom: 10px;
    margin-top: 0;
    z-index: 11;
    background: linear-gradient(0deg, rgba(60, 63, 79, 0) 0%, rgba(60, 63, 79, 1) 22%, rgba(60, 64, 79, 1) 100%);
  }

  .tabsRoot {
    padding: 0 20px;
    min-height: 74px;
  }
  .tabsIndicator {
    display: none;
  }
  .tabsFlexContainer {
    justify-content: space-between;
    margin: auto;
  }
  .tabsScrollButtons {
    width: 56px;
    opacity: 1;
    @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      height: 100%;
      width: 35px;
      background: linear-gradient(bottom, rgba(60, 63, 79, 0.1), rgba(60, 63, 79, 1));
      background: -webkit-linear-gradient(bottom, rgba(60, 63, 79, 0.1), rgba(60, 63, 79, 1));
      &:last-child {
        right: 0;
        left: auto;
      }
    }
  }
  .tabsScrollable {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .tabRoot {
    text-transform: uppercase;
    color: #fff;
    max-width: none;
    font-size: 14px;
    padding: 9px 0 0 0;
    min-width: 72px;

    @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
      flex-shrink: 0;
    }

    font-family: Roboto, sans-serif;

    &:hover {
      @media (min-width: ${({ theme }) => getBP(theme, "md")}) {
        color: #ffff1a;
        opacity: 1;
      }
      &.tabLabelIcon {
        & ${Icon} {
          @media (min-width: ${({ theme }) => getBP(theme, "md")}) {
            transform: scale(1.15);
          }
        }
      }
      & ${SimpleIcon} {
        @media (min-width: ${({ theme }) => getBP(theme, "md")}) {
          opacity: 0;
        }
      }
      & ${ColoredIcon} {
        @media (min-width: ${({ theme }) => getBP(theme, "md")}) {
          opacity: 1;
        }
      }
    }

    &.tabSelected {
      color: #ffff1a;
    }
  }
  .tabLabel {
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    letter-spacing: 0;
    @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
      font-size: 11px;
      line-height: 11px;
      padding: 0 6px;
    }
  }
  .tabLabelContainer {
    transition: 0.2s linear;
    padding: 5px 7px 0 8px;
    @media (min-width: ${({ theme }) => getBP(theme, "sm")}) {
      margin-top: 5px;
    }
  }
  .tabLabelIcon {
    & ${Icon} {
      transition: 0.2s linear;
    }
  }
`;
const Navigation = styled.div`
  width: 100%;
  @media (max-width: ${({ theme }) => getBP(theme, "sm")}) {
    width: 100%;
    position: relative;
  }
  margin: auto;

  & .category-text{
    padding: 6px 12px;
  }
  & .MuiSvgIcon-fontSizeSmall{
    font-size: 1.5rem;
  }
`;

const CategoriesComponent = () => {
  const { selectedCategory, categories, lobbyType } = useSelector((state) => state.gameCategories);
  const { operatorId, defaultCategory } = useSelector((state) => state.startUp);

  useEffect(() => {
    if (defaultCategory) {
      localStorage.setItem("selectedCategory", defaultCategory.toLowerCase());
    }
  }, []);

  const handleTabChanged = (event, tabId) => {
    localStorage.setItem("selectedCategory", tabId.toLowerCase());
  };

  if (isLobbyInIframe()) {
    const gameScrolled = document.getElementById("custom-sidebar-content-id");
    if (gameScrolled) {
      setTimeout(() => {
        window.parent.postMessage({ iFrameHeight: `${gameScrolled.scrollHeight}px` }, "*");
      });
    }
  }

  return (
    <CategoriesComponentWrap>
      <Navigation>
        {categories.length > 0 && (
          <Tabs
            value={(selectedCategory && selectedCategory) || false}
            onChange={(event, tabId) => handleTabChanged(event, tabId)}
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            classes={{
              root: "tabsRoot",
              indicator: "tabsIndicator",
              flexContainer: "tabsFlexContainer",
              scrollButtons: "tabsScrollButtons",
              scrollable: "tabsScrollable"
            }}
            id="categoryContainer"
          >
            {categories.map((item) => {
              const lowerName = item.Name.toLowerCase();

              return (
                <Tab
                  id={"category" + item.Name}
                  data-name={"category" + item.Name}
                  label={
                    <span id={"category" + item.Name + "Text"} className="category-text">
                      <FormattedMessage id={item.Name} />
                    </span>
                  }
                  key={item.Id}
                  value={lowerName}
                  component={Link}
                  replace
                  to={{
                    pathname: `/category/${lowerName}`,
                    search: `?operatorId=${operatorId}`
                  }}
                  icon={
                    <CategoryIcon lowerName={lowerName} selectedCategory={selectedCategory} lobbyType={lobbyType} />
                  }
                  classes={{
                    root: "tabRoot",
                    selected: "tabSelected",
                    wrapper: "tabLabel",
                    labelIcon: "tabLabelIcon"
                  }}
                  className="tabItem"
                />
              );
            })}
          </Tabs>
        )}
      </Navigation>
    </CategoriesComponentWrap>
  );
};

export default CategoriesComponent;
