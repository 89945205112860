// @flow

import type { ServiceResponse } from "../ResponseTypes";
import type { GameDataRequest } from "../clientModels/GameDataRequest";
import type { LCGameDataResponse, LCBCBigRoadMapType, LCDTBigRoadMapType } from "../serverModels/LCGameDataResponse";
import type { LCGameDataRequest } from "../serverModels/LCGameDataRequest";

import type {
  GameDataResponse,
  RGameDataResponse,
  BCGameDataResponse,
  BJGameDataResponse,
  DTGameDataResponse,
  GameData,
  ExternalGameData,
  BCBigRoadMapType,
  DTBigRoadMapType,
  TTGameDataResponse
} from "../clientModels/GameDataResponse";
import { store } from "src/store";
import { GAME_TYPE } from "../../../constants";

type FuncType = (...args: any) => void;

type GameType = {
  gameType: number,
  func: FuncType,
  serverProperty: string
};

export const mapGameDataRequest = (request: GameDataRequest): LCGameDataRequest => ({
  operatorID: request.operatorId,
  username: request.userName,
  accessPassword: request.registerToken,
  categoryId: request.categoryId,
  clientGames: "" // make here correct mapping
});

let oldGeneratedPlayerCount = 0;

const updateGameArray = (game, limit): GameData => ({
  gameName: game.gameName,
  gameId: parseInt(game.gameID, 10),
  gameType: parseInt(game.gameType, 10),
  dealerImageUrl: game.dealerImageUrl,
  dealerName: game.dealerName,
  playersCount: parseInt(game.PlayersCountInGame, 10),
  limitSet: {
    limitSetId: parseInt(limit.limitSetID, 10),
    minBet: parseInt(limit.minBet, 10),
    maxBet: parseInt(limit.maxBet, 10)
  }
});

const createNullArray = (rows: number, columns: number) => {
  const x: Array<Array<any>> = new Array(rows);
  for (let i = 0; i < rows; i++) {
    x[i] = new Array(columns);
    x[i].fill(null);
  }

  return x;
};

const bcBuildUIModel = (serverResponse: Array<LCBCBigRoadMapType>): Array<Array<BCBigRoadMapType | null>> => {
  let uiModel: Array<Array<BCBigRoadMapType | null>> = createNullArray(6, 30);
  let resultArraySequence = Array.isArray(serverResponse) ? serverResponse : [serverResponse];

  const serverResponceLength = resultArraySequence.length;
  let startIndex = 0;
  let lastElementX = parseInt(resultArraySequence[serverResponceLength - 1].X, 10);

  // slice the responce according to amount of data necessary for drawing (30 columns);
  if (lastElementX > 30) {
    let firstElementInRange = resultArraySequence.find((item) => parseInt(item.X, 10) === lastElementX - 30);
    startIndex = resultArraySequence.findIndex((item) => item === firstElementInRange);
    resultArraySequence = resultArraySequence.slice(startIndex);
  }

  resultArraySequence.forEach((item) => {
    const x = parseInt(item.X, 10) - (lastElementX > 30 ? lastElementX - 30 : 0);
    const y = parseInt(item.Y, 10);
    // build the ui model and return it for adding to the store;
    uiModel[y][x] = {
      result: parseInt(item.Result, 10),
      score: item.Score,
      isBankerPair: item.isBankerPair === "1",
      isPlayerPair: item.isPlayerPair === "1"
    };
  });

  return uiModel;
};

const dtBuildUIModel = (serverResponse: Array<LCDTBigRoadMapType | null>): Array<Array<DTBigRoadMapType | null>> => {
  let uiModel: Array<Array<DTBigRoadMapType | null>> = createNullArray(6, 30);
  let resultArraySequence = Array.isArray(serverResponse) ? serverResponse : [serverResponse];

  const serverResponceLength = resultArraySequence.length;
  let startIndex = 0;
  let lastElementX = parseInt(resultArraySequence[serverResponceLength - 1].X, 10);

  // slice the responce according to amount of data necessary for drawing (30 columns);
  if (lastElementX > 30) {
    let firstElementInRange = resultArraySequence.find((item) => parseInt(item.X, 10) === lastElementX - 30);
    startIndex = serverResponse.findIndex((item) => item === firstElementInRange);
    resultArraySequence = serverResponse.slice(startIndex);
  }

  resultArraySequence.forEach((item) => {
    const x = parseInt(item.X, 10) - (lastElementX > 30 ? lastElementX - 30 : 0);
    const y = parseInt(item.Y, 10);
    // build the ui model and return it for adding to the store;
    uiModel[y][x] = {
      result: parseInt(item.Result, 10)
    };
  });

  return uiModel;
};

const rUpdateGameArray = (array, game, limit) => {
  const baseObj = updateGameArray(game, limit);
  const rObj: RGameDataResponse = {
    ...baseObj,
    rouletteLastResults: game.rouletteLastResults.item
  };
  array.push(rObj);
};

const ttUpdateGameArray = (array, game, limit) => {
  const baseObj = updateGameArray(game, limit);
  const rObj: TTGameDataResponse = {
    ...baseObj,
    resultStatistics: game.thirtyTwoCardsGameResultStatistics
  };
  array.push(rObj);
};

const bcUpdateGameArray = (array, game, limit) => {
  const baseObj = updateGameArray(game, limit);
  let map = bcRoadMapByIdArray.find((roadMap) => roadMap.gameId === game.gameID);

  // mechanism for reusing the ui model if it was created on another limit;
  if (!map) {
    map = bcBuildUIModel(game.bigRoadMap.item);
    bcRoadMapByIdArray.push({ gameId: game.gameID, roadMap: map });
  } else {
    map = map.roadMap;
  }

  const bcObj: BCGameDataResponse = {
    ...baseObj,
    bigRoadMap: map
  };

  array.push(bcObj);
};

const bjUpdateGameArray = (array, game, limit) => {
  const baseObj = updateGameArray(game, limit);
  const bjObj: BJGameDataResponse = {
    ...baseObj,
    activeSeatsData: {
      playersNumber: parseInt(game.PlayersNumber, 10),
      playersNumbersInGame: parseInt(game.PlayersNumberInGame, 10)
    }
  };
  array.push(bjObj);
};

const dtUpdateGameArray = (array, game, limit) => {
  const baseObj = updateGameArray(game, limit);
  let map = dtRoadMapByIdArray.find((roadMap) => roadMap.gameId === game.gameID);

  // mechanism for reusing the ui model if it was created on another limit;
  if (!map) {
    map = dtBuildUIModel(game.DTBigRoadMap.item);
    dtRoadMapByIdArray.push({ gameId: game.gameID, roadMap: map });
  } else {
    map = map.roadMap;
  }

  const bcObj: DTGameDataResponse = {
    ...baseObj,
    bigRoadMap: map
  };

  array.push(bcObj);
};

const arr: Array<GameType> = [
  {
    gameType: 1,
    func: rUpdateGameArray,
    serverProperty: "rouletteLastResults"
  },
  { gameType: 2, func: bjUpdateGameArray, serverProperty: "PlayersNumber" },
  { gameType: 4, func: bcUpdateGameArray, serverProperty: "bigRoadMap" },
  { gameType: 12, func: dtUpdateGameArray, serverProperty: "DTBigRoadMap" },
  { gameType: 30, func: ttUpdateGameArray, serverProperty: "thirtyTwoCardsGameResultStatistics" }

  // TODO Create the rest of the gameType objects
];

const bcRoadMapByIdArray = []; // array for storing the ui models for baccarat in memory by gameID;
const dtRoadMapByIdArray = []; // array for storing the ui models for dragon tiger in memory by gameID;

const mapGameDataResponse = (data: LCGameDataResponse): GameDataResponse => {
  const games = [];

  if (data.liveGames) {
    const liveGame = data.liveGames.game;
    if (Array.isArray(liveGame)) {
      liveGame.forEach((game) => {
        parseGame(games, game);
      });
    } else {
      parseGame(games, liveGame);
    }

    return { liveGames: games };
  }

  if (data.externalGames) {
    const externalGames = data.externalGames.game;
    const providers = [];
    const slotLines = [];
    if (Array.isArray(externalGames)) {
      externalGames.forEach((game) => {
        parseExternalGame(games, providers, slotLines, game);
      });
    } else {
      parseExternalGame(games, providers, slotLines, externalGames);
    }

    return { externalGames: { games, providers, slotLines } };
  }
  return { liveGames: games };
};

const parseExternalGame = (games, providers, slotLines, game) => {
  const item: ExternalGameData = {
    gameId: parseInt(game.gameID, 10),
    gameName: game.gameName,
    gameProvider: game.gameProvider,
    imageUrl: game.imageUrl,
    typeID: game.typeID,
    linesCount: game.linesCount ? parseInt(game.linesCount, 10) : 0,
    jackpotGame: !!parseInt(game.jackpotGame, 10)
  };
  providers.indexOf(game.gameProvider) < 0 && providers.push(game.gameProvider);
  slotLines.indexOf(game.linesCount) < 0 && slotLines.push(game.linesCount);
  games.push(item);
};

const parseGame = (array, game) => {
  if (Number(game.gameType) !== GAME_TYPE.blackjack) {
    const { playersCount } = store.getState().startUp;
    const currentGeneratedPlayers =
      playersCount && parseInt(Math.random() * (playersCount.max - playersCount.min) + playersCount.min, 10);
    oldGeneratedPlayerCount =
      playersCount && oldGeneratedPlayerCount === currentGeneratedPlayers
        ? currentGeneratedPlayers - 10 < playersCount.min
          ? currentGeneratedPlayers + 10
          : currentGeneratedPlayers - 10
        : currentGeneratedPlayers;

    game.PlayersCountInGame = playersCount ? oldGeneratedPlayerCount : game.PlayersCountInGame;
  }
  if (Array.isArray(game.limitSetList.limitSet)) {
    game.limitSetList.limitSet.forEach((limit) => {
      const gameType = parseInt(game.gameType, 10);
      const objByGameType: any = arr.find((obj) => obj.gameType === gameType);
      if (objByGameType && game[objByGameType.serverProperty]) {
        objByGameType.func(array, game, limit);
      } else {
        const baseObj = updateGameArray(game, limit);
        array.push({ ...baseObj });
      }
    });
  } else {
    const limit = game.limitSetList.limitSet;
    const gameType = parseInt(game.gameType, 10);
    const objByGameType: any = arr.find((obj) => obj.gameType === gameType);
    if (objByGameType && game[objByGameType.serverProperty]) {
      objByGameType.func(array, game, limit);
    } else {
      const baseObj = updateGameArray(game, limit);
      array.push({ ...baseObj });
    }
  }
};

export const resolveResponse = (response: ServiceResponse<LCGameDataResponse>): GameDataResponse => {
  return mapGameDataResponse(response.data);
};
